import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import TemplateHeader from "../../components/TemplatePages/TemplateHeader/TemplateHeader";
import TitleSmBgAndDesc from "../../components/TemplatePages/TitleSmBgAndDesc/TitleSmBgAndDesc";

export const WebinarReplayTemplate = ({ frontmatter }) => {
  return (
    <React.Fragment>
      <TemplateHeader
        bgTitle={frontmatter.compellingHeadline.title}
        videoURL={frontmatter.compellingHeadline.videoURL}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.secondBlock.smTitle}
        bgTitle={frontmatter.secondBlock.bgTitle}
        description={frontmatter.secondBlock.description}
        buttonLink={frontmatter.secondBlock.buttonLink}
      />
    </React.Fragment>
  );
};

WebinarReplayTemplate.propTypes = {
  frontmatter: PropTypes.object,
};

const WebinarReplay = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout  title={frontmatter.compellingHeadline.title || 'Info Page'}>
      <WebinarReplayTemplate frontmatter={frontmatter} />
    </Layout>
  );
};

WebinarReplay.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WebinarReplay;

export const pageQuery = graphql`
  query WebinarReplay($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        compellingHeadline {
          title
          videoURL
        }
        secondBlock {
          smTitle
          bgTitle
          description
          buttonLink {
            label
            path
          }
        } 
      }
    }
  }
`;
